import "../../assets/stylesheets/LanSwitch.css";
import UNITED_STATES from '../../assets/images/united-states.png';
import DO from '../../assets/images/dominican-republic.png';
import { React, useState} from "react";
import $ from "jquery";
import Components from "../../services/utils/language/language";

let isFirst = true;

let LanSwitch = () => {
  const [state, setState] = useState(true); 
  const isMainLanguageOn = state;

  function handleChange() {
    if (isFirst) {
      firstLoad();
    } else {
      if (isMainLanguageOn) {
        turnMainLanguage();
        $("#container").removeClass("on");
      } else {
        turnSecondLanguage();
        $("#container").addClass("on");
      }
    }
  }

  function firstLoad(){
    $("#container").css("background-image", `url('${DO}')`);
    $("#container").addClass("on");
    Components.Translate(false);
    isFirst = false;
  }

  function turnMainLanguage() {
    $("#container").css("background-image", `url('${UNITED_STATES}')`);
    setState(false);
    loadWords();
  }
  function turnSecondLanguage() {
    $("#container").css("background-image", `url('${DO}')`);
    setState(true);
    loadWords();
  }

  function loadWords(){
    Components.Translate(isMainLanguageOn);
  }

  return (
    <div className="switch-container" onClick={handleChange}>
      <div id="container" className="switch-container switch"></div>
    </div>
  );
};

export default LanSwitch;
