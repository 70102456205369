import '../../assets/stylesheets/home.css';
import pctPortrait from '../../assets/images/portrait.png';
import React, {useRef} from "react";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';  
import { ReactTyped } from 'react-typed';
import Perfil from '../../components/perfil';
import About from '../../components/about';
import Services from '../../components/services';
import Portfolio from '../../components/portfolio';
import Contact from '../../components/contact';
import Header from '../headers/sectionHeader';

gsap.registerPlugin(useGSAP, ScrollTrigger);

const HomeForm = () => {
  const { _main } = useRef();

  useGSAP(
    () => {
      const section = gsap.utils.toArray(".section")[0];
      const wrapper = gsap.utils.toArray(".wrapper")[0];
      const mainContainer = gsap.utils.toArray(".image-container")[0];
      const pctPortrait = gsap.utils.toArray("#pctPortrait")[0];

      gsap
        .timeline({
          scrollTrigger: {
            trigger: wrapper,
            start: "top top",
            end: "+=150%",
            pin: wrapper,
            scrub: true,
            markers: false,
          },
        })
        .to(mainContainer, {
          transformOrigin: "center center",
          ease: "power1.inOut",
          display: "none",
          opacity: 0,
          scale: 2,
          z: 450,
        })
        .to(pctPortrait, {
          transformOrigin: "center center",
          ease: "power1.inOut",
          opacity: 0,
          scale: 2,
          z: 450,
        })

        .to(
          section,
          {
            scale: 1,
            transformOrigin: "center center",
            ease: "power1.inOut",
          },
          "<"
        );
    },
    { scope: _main }
  );

  return (
    <div className="wrapper">
      <div className="content">
        <Header />
        <section id="perfil" className="section one">
         
          <Perfil />
        </section>
        <section id="about" className="section two">
          <About />
        </section>
        <section id="services" className="section three">
          <Services />
        </section>
        <section id="portfolio" className="section four">
          <Portfolio />
        </section>
        <section id="contact" className="section five">
          <Contact />
        </section>
      </div>

      <div ref={_main} className="image-container">
        <div className="frontPortrait logo-left">
          <p className="shine-text">ING ANTHONY REYES DURAN</p>
        </div>
        <div className="frontPortrait logo-center">
          <p>WELCOME</p>
          <p className="SecondTitle">
            I AM A{" "}
            <ReactTyped
              className="MainTitle"
              strings={["DEVELOPER", "SOFTWARE ENGINEER"]}
              typeSpeed={100}
              backSpeed={50}
              startDelay={800}
              loop
            />
          </p>
        </div>
        <img id="pctPortrait" src={pctPortrait} alt="image"></img>
      </div>
    </div>
  );
};

export default HomeForm;