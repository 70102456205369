import { useEffect, useState } from 'react';
import '../../assets/stylesheets/contact.css';
import Language from "../../services/utils/language/language";
import Cards from '../cards/social';

const delay = 500;

const Contact = () => {
  const [, setTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, delay);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="section-container five noselect">
      <header className="header-section two">
        <p>{Language.General.contact.title1}</p>
      </header>
      <main>
        <article className="article-section three">
          <Cards />
        </article>
      </main>
    </div>
  );
};

export default Contact;