import '../../assets/stylesheets/header.css';
import Components from "../../services/utils/language/language";
import LanSwitch from '../../components/LanSwitch/LanSwitch.js';
import React, {useEffect, useState} from "react";
import Logo from '../../assets/images/icon.ico';
const delay = 500;

const Header = () => {
    const [, setTime] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(() =>{
            setTime(new Date());
        }, delay);
        return () => {
            clearInterval(interval);
        }
    }, []);

    return (
        <header className="header-section-one noselect">
          <img className='header-logo' src={Logo} alt='header-logo'></img>
          <p className="shine-text2">ING ANTHONY REYES DURAN</p>
          <nav>
        <ul>
          <li>
            <a href='#perfil'>{Components.General.mainbar.home}</a>
          </li>
          <li>
            <a href='#about'>{Components.General.mainbar.about}</a>
          </li>
          <li>
            <a href='#services'>{Components.General.mainbar.services}</a>
          </li>
          <li>
            <a href='#portfolio'>{Components.General.mainbar.portfolio}</a>
          </li>
          <li>
            <a href='#contact'>{Components.General.mainbar.contact}</a>
          </li>
          <li>
            <LanSwitch />
          </li>
        </ul>
      </nav>
        </header>
    )
}

export default Header;