import "../../assets/stylesheets/services.css";
import Cards from "../cards/services.js";
import Language from "../../services/utils/language/language";
import { useEffect, useState } from "react";

const delay = 500;

const Services = () => {
  const [, setTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, delay);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="section-container three noselect">
      <header className="header-section two">
        <p>{Language.General.services.title1}</p>
      </header>
      <main>
        <article className="article-section three">
          <Cards />
        </article>
      </main>
    </div>
  );
};

export default Services;