import '../../assets/stylesheets/portfolio.css';
import Cards from '../cards/porfolio.js';
import Language from '../../services/utils/language/language.js';
import { useEffect, useState } from 'react';

const delay = 500;

const Portfolio = () => {
  const [, setTime] =useState(new Date());
  useEffect(()=> {
    const interval = setInterval(() => {
      setTime(new Date());
    }, delay);

    return () => {
      clearInterval(interval);
    }

  }, [])
  
  return (
    <div className="section-container four noselect">
        <header className="header-section two">
          <p>{Language.General.portfolio.title1}</p>
        </header>
        <main>
          <article className="article-section four">
            <Cards/>
          </article>
        </main>
    </div>
  );
};

export default Portfolio;