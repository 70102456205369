import "../../assets/stylesheets/cards.css";
import img1 from '../../assets/images/mail.png';
import img2 from '../../assets/images/github.png';
import img3 from '../../assets/images/linkedin.png';
import img4 from '../../assets/images/facebook.png';
import img5 from '../../assets/images/instagram.png';

const cards = [
    {name: "Mail", url: "mailto:anthony_reyes_duran@hotmail.com", src: img1},
    {name: "GitHub", url: "https://github.com/MasterSSOA", src: img2},
    {name: "Linkedin", url: "https://www.linkedin.com/in/anthony-reyes-dur%C3%A1n-31002a29a", src: img3},
    {name: "Facebook", url: "https://www.facebook.com/anthony.reyesduran/", src: img4},
    {name: "Instagram", url: "https://www.instagram.com/anthony_reyes_duran?igsh=eWZscGdzemR2YXp0&utm_source=qr", src: img5},
];

const Cards = () => {
  return (
    <div className="contain">
      {cards.map((card) => (
        <a className="cards btn" href={card.url}>
          <img src={card.src} alt={card.name}></img>
        </a>
      ))}
    </div>
  );
};

export default Cards;