import "../../assets/stylesheets/cards.css";
import front1 from '../../assets/gif/landingPage.gif';
import back1 from '../../assets/gif/back1.gif';
import front2 from '../../assets/gif/razorPages.gif';
import back2 from '../../assets/gif/back2.gif';
import front3 from '../../assets/gif/posInterface.gif';
import back3 from '../../assets/gif/back3.gif';
import front4 from '../../assets/gif/webApp.gif';
import back4 from '../../assets/gif/back4.gif';
import front5 from '../../assets/gif/webAPI.gif';
import back5 from '../../assets/gif/back5.gif';
import front7 from '../../assets/gif/windowsApp.gif';
import back7 from '../../assets/gif/back7.gif';
import Language from "../../services/utils/language/language";
import { useEffect, useState } from "react";

const cards = [
  {
    srcFront: front1,
    srcBack: back1,

  },
  {
    srcFront: front2,
    srcBack: back2,
  },
  {
    srcFront: front3,
    srcBack: back3,
  },
  {
    srcFront: front4,
    srcBack: back4,
  },
  {
    srcFront: front5,
    srcBack: back5,
  },
  {
    srcFront: front7,
    srcBack: back7,
  },
];

const delay = 500;
const Cards = () => {
  const [, setTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, delay);

    return () => {
      clearInterval(interval);
    }
  }, []);
  
    return (
      <div className="contain">
        {cards.map((card , idx) => (
          <div className="cards portfolio">
            <div className="flip-box">
              <div className="flip-front">
                <img src={card.srcFront}></img>
              </div>
              <div className="flip-back">
                <img src={card.srcBack}></img>
              </div>
            </div>
            <div>
              <p>{Language.General.portfolio.cards[idx].project}</p>
              <p className="description2">({Language.General.portfolio.cards[idx].tecnology})</p>
            </div>
          </div>
        ))}
      </div>
    );
}

export default Cards;