import "../../assets/stylesheets/about.css";
import hat from '../../assets/images/graduation-hat.png';
import Language from "../../services/utils/language/language";
import React, {useEffect, useState} from "react";

const delay = 500;

const About = () => {
  const [, setTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, delay);

    return () => {
      clearInterval(interval);
    };
  }, []);

    return (
      <div className="section-container two noselect">
         <header className="header-section two">
           <img src={hat} alt="image-hat"></img>
            <p>{Language.General.about.title1}</p>
          </header>
        <main>
          <article className="article-section two">
            <p>{Language.General.about.content1}</p>
            <p>{Language.General.about.content2}</p>
          </article>
        </main>
      </div>
    );
}

export default About;