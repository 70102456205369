import "../../assets/stylesheets/cards.css";
import img1 from "../../assets/images/js.png";
import img2 from "../../assets/images/html-5.png";
import img3 from "../../assets/images/css.png";
import img4 from "../../assets/images/structure.png";
import img5 from "../../assets/images/c-sharp.png";
import img6 from "../../assets/images/database.png";
import img7 from "../../assets/images/solution.png";
import img8 from "../../assets/images/api.png";
import { useEffect, useState } from "react";
import Language from "../../services/utils/language/language";

let cards = [
  {
    resourse: "JavaScript",
    projects: 10,
    url: img1,
  },
  {
    resourse: "HTML",
    projects: 10,
    url: img2,
  },
  {
    resourse: "CSS",
    projects: 10,
    url: img3,
  },
  {
    resourse: "Framework React JS",
    projects: 7,
    url: img4,
  },
  {
    resourse: "Windows App (C#)",
    projects: 50,
    url: img5,
  },
  {
    resourse: "Transact-SQL (MS SQL)",
    projects: 90,
    url: img6,
  },
  {
    resourse: "Problem Solving ",
    projects: 300,
    url: img7,
  },
  {
    resourse: "Web APIs",
    projects: 5,
    url: img8,
  },
];

const delay = 500;

const Cards = () => {
  const [, setTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, delay);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="contain">
      {cards.map((card, idx) => (
        <div className="cards">
          <img src={card.url}></img>
          <div>
            <p>{card.resourse}</p>
            <p>{Language.General.services.cards[idx].time}</p>
            <p>
              {Language.General.services.content1} {card.projects}+
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Cards;
