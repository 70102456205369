class Language {
  static Spanish = {
    mainbar: {
      home: "Inicio",
      about: "Acerca",
      services: "Servicios",
      portfolio: "Portafolio",
      contact: "Contacto",
    },
    perfil: {
      title1: '"¡Hola Mundo!"',
      title2: "Soy ",
      title21: "Anthony Reyes Durán.",
      title3: "Ingeniero de Software",
      title4: "Ubicado en la República Dominicana",
      title5: "Nivel de Ingles : Intermedio",
      btnTitle1: "Contratame",
      tap: "Tocar para desplazarse hacia abajo!",
    },
    about: {
      title1: "RESUMEN",
      content1: `Profesional con titulación de grado de Ingeniería desde el 2020, egresado de la
                        Universidad UNAPEC en Santo Domingo, Republica Dominicana. Con más de 10 años de experiencia en el área de las Ciencias de Información, 
                        trabajando desde el 2015 como auxiliar de sistemas, analista programador y administrador 
                        de desarrollo de analíticas y consultas de bases de datos, llevando a cabo decenas
                        de herramientas, servicios, interfaces de programación, sistemas informáticos, análisis algorítmicos y
                        todos orientados en el sistema operativo Windows y basados en lenguaje C# en su mayoría.`,
      content2: `Me considero senior en la resolución de problemas, medior en el desarrollo de aplicaciones y herramientas propias de Windows,  
                        medior en la gestión de creaciones de consultas en base de datos (MS SQL) y Junior en el desarrollo de aplicaciones
                        web con enfoque al marco de trabajo de React JS, poseyendo más de 6 meses de experiencia y la elaboración de más de 
                        10 proyectos del mismo.`,
    },
    services: {
      title1: "SERVICIOS",
      cards: [
        { time: "Poco Más de 8 meses" },
        { time: "Poco Más de 8 meses" },
        { time: "Poco Más de 8 meses" },
        { time: "Poco Más de 6 meses" },
        { time: "Poco Más de 7 años" },
        { time: "Poco Más de 8 años" },
        { time: "Poco Más de 10 años" },
        { time: "Poco Más de 6 meses" },
      ],
      content1: "proyectos",
    },
    portfolio: {
      title1: "PORTAFOLIO",
      cards: [
        {
          project: "Landing Page",
          tecnology: "React JS & Biblioteca de animación GSAP",
        },
        {
          project: "Sitio Web E-Commerce",
          tecnology: "Razor Pages .Net Core",
        },
        {
          project: "Sistema Punto de Venta - Interfaz Web",
          tecnology: "React JS",
        },
        {
          project: "Aplicación Web ",
          tecnology: "React JS",
        },
        {
          project: "Web API",
          tecnology: "JavaScript Node.js",
        },
        {
          project: "Software de Gestión de Inventario y Ventas",
          tecnology: ".Net Core Aplicación Windows",
        },
      ],
    },
    contact: {
      title1: "CONTACTAME",
      content1: `Professional with a degree in Engineering since 2020, graduated from UNAPEC University in Santo Domingo, Dominican Republic. With more than 10 years of experience in the area of ​​Information Sciences, working since 2015 as a systems assistant, programmer analyst and development administrator of analytics and database queries, carrying out dozens of tools, services, programming interfaces, computer systems, algorithmic analysis and all oriented on the Windows operating system and based on the C# language for the most part.`,
      content2: `I consider myself a senior in problem resolving, medior in the development of Windows applications and tools, medior in the management of database query creations (MS SQL) and Junior in the development of web applications with a focus on the React JS framework, with more than 6 months of experience and the development of more than 10 projects.`,
    },
  };

  static English = {
    mainbar: {
      home: "Home",
      about: "About",
      services: "Services",
      portfolio: "Portfolio",
      contact: "Contact",
    },
    perfil: {
      title1: '"Hello World!"',
      title2: "I am",
      title21: "Anthony Reyes Durán.",
      title3: "A Software Engineer",
      title4: "Based in Dominican Republic",
      title5: "B1 English Level",
      btnTitle1: "Hire Me",
      tap: "Tap to scroll down!",
    },
    about: {
      title1: "ABOUT ME",
      content1: `Professional with a degree in Engineering since 2020, graduated from UNAPEC University in Santo Domingo, Dominican Republic. With more than 10 years of experience in the area of ​​Information Sciences, working since 2015 as a systems assistant, programmer analyst and development administrator of analytics and database queries, carrying out dozens of tools, services, programming interfaces, computer systems, algorithmic analysis and all oriented on the Windows operating system and based on the C# language for the most part.`,
      content2: `I consider myself a senior in problem resolving, medior in the development of Windows applications and tools, medior in the management of database query creations (MS SQL) and Junior in the development of web applications with a focus on the React JS framework, with more than 6 months of experience and the development of more than 10 projects.`,
    },
    services: {
      title1: "SERVICES",
      cards: [
        { time: "Over 8 months" },
        { time: "Over 8 months" },
        { time: "Over 8 months" },
        { time: "Over 6 months" },
        { time: "Over 7 years" },
        { time: "Over 8 years" },
        { time: "Over 10 years" },
        { time: "Over 6 months" },
      ],
      content1: "projects",
    },
    portfolio: {
      title1: "PORTFOLIO",
      cards: [
        {
          project: "Landing Page",
          tecnology: "React JS & GSAP Animation Library",
        },
        {
          project: "E-Commerce Website",
          tecnology: "Razor Pages .Net Core",
        },
        {
          project: "POS System - Web Interface ",
          tecnology: "React JS",
        },
        {
          project: "Web Application",
          tecnology: "React JS",
        },
        {
          project: "Web API",
          tecnology: "JavaScript Node.js",
        },
        {
          project: "Sales & Inventory Management Software",
          tecnology: "Windows App .Net Core",
        },
      ],
    },
    contact: {
      title1: "CONTACT ME",
      content1: `Professional with a degree in Engineering since 2020, graduated from UNAPEC University in Santo Domingo, Dominican Republic. With more than 10 years of experience in the area of ​​Information Sciences, working since 2015 as a systems assistant, programmer analyst and development administrator of analytics and database queries, carrying out dozens of tools, services, programming interfaces, computer systems, algorithmic analysis and all oriented on the Windows operating system and based on the C# language for the most part.`,
      content2: `I consider myself a senior in problem resolving, medior in the development of Windows applications and tools, medior in the management of database query creations (MS SQL) and Junior in the development of web applications with a focus on the React JS framework, with more than 6 months of experience and the development of more than 10 projects.`,
    },
  };

  static General = this.English;

  static Translate(isMainLanguageOn) {
    return (this.General = isMainLanguageOn ? this.English : this.Spanish);
  }
}

export default Language;
