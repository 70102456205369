import React from "react";
import '../../assets/stylesheets/home.css';
import Main from "../../layouts/main/index.js";

const Home = () => {
    return(
       <div className="container">
         <Main />
       </div>
    );
};

export default Home;