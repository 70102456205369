import "../../assets/stylesheets/perfil.css";
import '../../assets/stylesheets/LanSwitch.css';
import ptcMain from "../../assets/images/mainPortrait.png";
import pctTap from '../../assets/images/tap.png';
import Components from "../../services/utils/language/language";
import React, {useEffect, useState} from "react";

let classes = [
  { class: "gallery-item gallery-item-1" },
  { class: "gallery-item gallery-item-2" },
  { class: "gallery-item gallery-item-3" },
];
const delay = 500;
let counter = 0;

const Perfil = () => {
  const [, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
      counter++;
    }, delay);

    return () => {
      clearInterval(interval);
      if (counter === 10) counter = 0;
    };
  }, updateGallery());

  function updateGallery() {
    if (counter === 10) classes.push(classes.shift());
  }

  return (
    <div className="container noselect">
      <header>
        <p>created by Ing. Anthony Reyes Durán.</p>
      </header>
      <main>
        <article className="article-section-one">
          <p>{Components.General.perfil.title1}</p>
          <p className="MainTitle">{Components.General.perfil.title2} <span id="MainTitleSpan">{Components.General.perfil.title21}</span></p>
          <p id="MainTitleDevice" className="MainTitle">{Components.General.perfil.title21}</p>
          <p className="MainTitle">{Components.General.perfil.title3}</p>
          <p>
            {Components.General.perfil.title4} <br />{" "}
            {Components.General.perfil.title5}
          </p>
          <a href="#contact">
            {Components.General.perfil.btnTitle1}
            <div className="shine"></div>
          </a>
          <div className='tab'>
            <img src={pctTap} alt="image2"></img>
            <p>{Components.General.perfil.tap}</p>
          </div>
        </article>
        <aside>
          <div className="gallery-container">
            <img
              id="ptcMain1"
              src={ptcMain}
              className={classes[0].class}
              alt="imagen2"
            ></img>
            <img src={ptcMain} className={classes[1].class} alt="imagen1"></img>
            <img
              id="ptcMain3"
              src={ptcMain}
              className={classes[2].class}
              alt="imagen3"
            ></img>
          </div>
        </aside>
      </main>
    </div>
  );
};

export default Perfil;
